import React, { useState, useEffect, useRef } from "react";
import "./Reviews.css";
import moment from "moment/moment";
import profileUserImage from "../../../Components/Think.jpeg";
import { GetReviews } from "../../../Features/kitchenSlice";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../../Store/store";
import html2canvas from "html2canvas";

function Reviews() {
  const dispatch = useAppDispatch();
  const { userData, reviews } = useAppSelector(selectKitchen);
  const [selectedReview, setSelectedReview] = useState(null);
  const modalRef = useRef(null);

  const [likedReviews, setLikedReviews] = useState({});
  const [dislikedReviews, setDislikedReviews] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [newCommentUserImage, setNewCommentUserImage] =
    useState(profileUserImage);
  const [hasReviews, setHasReviews] = useState(false);

  useEffect(() => {
    if (!reviews) {
      const isBasicStaff = userData.Role === "basic";
      const kitchenId = isBasicStaff ? userData.KitchenId : userData.Id;
      dispatch(GetReviews(kitchenId));
    }
  }, [reviews]);

  const handleShare = (review) => {
    setSelectedReview(review);
  };

  const closeShareModal = () => {
    setSelectedReview(null);
  };

  const handleScreenshot = async () => {
    if (modalRef.current) {
      const canvas = await html2canvas(modalRef.current);
      const image = canvas.toDataURL("image/png");

      // Trigger download of the screenshot
      const link = document.createElement("a");
      link.href = image;
      link.download = "review_screenshot.png";
      link.click();
    }
  };

  function formatTimestampToDateString(timestamp) {
    const date = new Date(timestamp);
    return `${date.toDateString()} ${date.toLocaleTimeString()}`;
  }

  const handleLikeClick = (index) => {
    const updatedReviews = [...reviews];
    if (!likedReviews[index]) {
      updatedReviews[index].likeCount += 1;
      updatedReviews[index].dislikeCount -= dislikedReviews[index] ? 1 : 0;
      setLikedReviews({ ...likedReviews, [index]: true });
      setDislikedReviews({ ...dislikedReviews, [index]: false });
    } else {
      updatedReviews[index].likeCount -= 1;
      setLikedReviews({ ...likedReviews, [index]: false });
    }
  };

  const handleDislikeClick = (index) => {
    const updatedReviews = [...reviews];
    if (!dislikedReviews[index]) {
      updatedReviews[index].dislikeCount += 1;
      updatedReviews[index].likeCount -= likedReviews[index] ? 1 : 0;
      setDislikedReviews({ ...dislikedReviews, [index]: true });
      setLikedReviews({ ...likedReviews, [index]: false });
    } else {
      updatedReviews[index].dislikeCount -= 1;
      setDislikedReviews({ ...dislikedReviews, [index]: false });
    }
  };

  const handleShowInputClick = () => {
    setShowInput(true);
  };

  const handleSendClick = () => {
    const timestamp = moment().format("MMMM Do YYYY, h:mm A");
    const newReviewObj = {
      Reviewer: userData.KitchenName,
      Review: newComment,
      userImage: newCommentUserImage,
      likeCount: 0,
      dislikeCount: 0,
      date: timestamp,
    };

    const updatedReviews = [...reviews, newReviewObj];

    setNewComment("");
    setShowInput(false);
    setIsButtonDisabled(true);
    setHasReviews(true);
  };

  const handleCommentInputChange = (e) => {
    const inputText = e.target.value;
    setNewComment(inputText);
    setIsButtonDisabled(inputText === "");
  };

  useEffect(() => {
    if (reviews) {
      setHasReviews(true);
    }
  }, [reviews]);

  return (
    <div className="p-4 sm:p-6 min-h-screen lg:ml-60">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-2 text-center">
          Customer Reviews
        </h1>
        <p className="text-gray-600 mb-6 text-center text-sm sm:text-base">
          Hear what customers are saying about your restaurant and service!
        </p>

        <div className="space-y-4 sm:space-y-6">
          {reviews &&
            reviews.map((review) => (
              <div
                key={review.Id}
                className="bg-white border border-gray-200 rounded-lg p-4 shadow-md relative flex flex-col space-y-3 sm:space-y-2"
              >
                <span className="absolute top-2 right-2 bg-quickee text-white text-xs px-2 py-1 rounded-md font-bold">
                  Powered by QuicKee
                </span>

                <div className="flex flex-col">
                  <h2 className="text-base sm:text-lg font-semibold text-gray-800">
                    {review.Reviewer}
                  </h2>
                  <p className="text-gray-700 text-sm sm:text-base">
                    {review.Review}
                  </p>
                </div>

                <div className="flex items-center justify-between text-sm sm:text-base text-gray-500">
                  <button className="flex items-center space-x-1 hover:text-blue-500">
                    <span>👍</span>
                    <span>{review.AgreeCount}</span>
                  </button>
                  <button className="flex items-center space-x-1 hover:text-red-500">
                    <span>👎</span>
                    <span>{review.DisagreeCount}</span>
                  </button>
                  <button
                    className="text-quickee hover:underline font-medium"
                    onClick={() => handleShare(review)}
                  >
                    Share
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      {selectedReview && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          ref={modalRef}
        >
          <div className="bg-white rounded-lg p-6 max-w-sm sm:max-w-md w-full shadow-lg relative">
            <h2 className="text-lg font-bold text-gray-800 mb-4 text-center">
              Share this review
            </h2>
            <div className="bg-gray-100 p-4 rounded-lg mb-4">
              <p className="text-gray-800 font-medium mb-2 text-sm sm:text-base">
                "{selectedReview.Review}"
              </p>
              <span className="text-xs sm:text-sm text-gray-500">
                - {selectedReview.Reviewer}
              </span>
              <span className="absolute top-2 right-2 bg-quickee text-white text-xs px-2 py-1 rounded-md font-bold">
                Powered by QuicKee
              </span>
            </div>
            <div className="flex flex-col space-y-3 sm:space-y-0 sm:flex-row sm:space-x-4">
              <button
                onClick={handleScreenshot}
                className="bg-quickee text-white px-4 py-2 rounded-lg shadow hover:bg-gray-600 text-center"
              >
                Take Screenshot
              </button>
              <button
                className="mt-4 text-gray-600 hover:underline text-sm text-center"
                onClick={closeShareModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reviews;
