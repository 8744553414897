// import React, { useState } from "react";
// import "./App.css";
// import { useRoutes } from "react-router-dom";
// import ForgotPassword from "./MainCode/ForgotPassword/ForgotPassword";
// import ResetPassword from "./MainCode/ForgotPassword/ResetPassword";
// import VerifyEmail from "./MainCode/SideBarLinkPage/VerifyEmail/VerifyEmail";
// import ResendCode from "./MainCode/SideBarLinkPage/VerifyEmail/ResendVerifyEmail";
// import SignIn from "./MainCode/SignInScreen/SignIn";
// import Signup from "./MainCode/SignUpScreen/Signup";
// import Home from "./MainCode/HomeScreen/Home";
// import {
//   AdminGetAllKitchen,
//   AdminGetAllUniversity,
//   AdminGetAllAmount,
//   AdminGetMonthlyRev,
//   AdminGetWeeklyAmount,
//   AdminGetDailyRev,
//   GetAllAndGroupByDayInMonth,
//   GetKitchenOrders,
//   AdminGetAllUsers,
//   AdminGetTodayUsers,
// } from "./Features/kitchenSlice";
// import { useDispatch } from "react-redux";
// import { useEffect } from "react";
// import { setupAxiosInterceptors } from "../src/Features/utils";
// import AdminSignup from "./MainCode/AdminDashboard/AdminSignup/adminSignup";
// import AdminSignin from "./MainCode/AdminDashboard/AdminSignin/adminSignin";
// import AdminBoard from "./MainCode/AdminDashboard/AdminBoard/adminBoard";
// import DeleteAccount from "./MainCode/DeleteAccount/deleteAccount";
// import DownloadApk from "./MainCode/DownloadApp/DownloadApk";
// import QuicKeePage from "./MainCode/QuicKee/QuickeePage";
// import { selectKitchen, useAppSelector } from "./Store/store";
// import UseSocketIO from "./UseSocketIO";
// import UserDetails from "./MainCode/AdminDashboard/AdminBoard/Others/user-details";

// // function App() {
// //   const dispatch = useDispatch();
// //   const { userData, socketIOmessages } = useAppSelector(selectKitchen);

// //   useEffect(() => {
// //     setupAxiosInterceptors(dispatch);

// //     // Cleanup function
// //     return () => {
// //       // Remove interceptors or perform any cleanup if needed
// //     };
// //   }, [dispatch]);

// //   // socket.io
// //   const [shouldReconnect, setShouldReconnect] = useState(true);
// //   const { isConnected } = UseSocketIO(userData?.Id, shouldReconnect);

// //   useEffect(() => {
// //     if (!isConnected) {
// //       const retryTimeout = setTimeout(() => {
// //         // console.log("It has detected socketio disconnection");
// //         setShouldReconnect(true);
// //       }, 2000); // Adjust the delay as needed

// //       return () => clearTimeout(retryTimeout);
// //     }
// //   }, [isConnected]);

// //   useEffect(() => {
// //     if (isConnected) {
// //       setShouldReconnect(false);
// //     }
// //   }, [isConnected]);

// //   // end socket.io

// //   useEffect(() => {
// //     // Example: handle socket messages
// //     if (socketIOmessages?.length > 0) {
// //       //console.log("socket messages: ", ...socketIOmessages);
// //       //console.log("messageQueue length: ", socketIOmessages?.length);
// //     }

// //     const fetch = setInterval(() => {
// //       if (userData) {
// //         //For Registered Kitchen
// //         dispatch(AdminGetAllKitchen());

// //         //For the University Card
// //         dispatch(AdminGetAllUniversity());

// //         //for the table
// //         dispatch(AdminGetAllAmount());

// //         //QuicKee Finances Per Month
// //         dispatch(AdminGetMonthlyRev());

// //         //QuicKee Finance Per Week
// //         dispatch(AdminGetWeeklyAmount());

// //         //QuicKee Finance Per Day
// //         dispatch(AdminGetDailyRev());

// //         // GetAllAndGroupByDayInMonth
// //         const monthNum = new Date().getMonth() + 1;
// //         let monthStr = monthNum < 10 ? "0" + monthNum : monthNum;
// //         const month = new Date().getFullYear().toString() + "-" + monthStr;
// //         dispatch(GetAllAndGroupByDayInMonth(userData?.KitchenEmail, month));

// //         //QuicKee Total Users
// //         dispatch(AdminGetAllUsers());

// //         //QuicKee Daily Users
// //         dispatch(AdminGetTodayUsers());

// //         dispatch(GetKitchenOrders(userData?.KitchenEmail));
// //       }
// //     }, 5000);

// //     return () => clearInterval(fetch);
// //   }, [socketIOmessages, userData]);
// //   /* Ending of WebSocket setup */

// //   const routes = useRoutes([
// //     { path: "/", element: <Signup /> },
// //     { path: "/signIn", element: <SignIn /> },
// //     { path: "/home", element: <Home /> },
// //     { path: "/forgotPassword", element: <ForgotPassword /> },
// //     { path: "/resetPassword/:email", element: <ResetPassword /> },
// //     { path: "/verifyEmail", element: <VerifyEmail /> },
// //     { path: "/resendEmail", element: <ResendCode /> },
// //     { path: "/quildSignup", element: <AdminSignup /> },
// //     { path: "/quildSignin", element: <AdminSignin /> },
// //     { path: "/quildBoard", element: <AdminBoard /> },
// //     { path: "/deleteaccount", element: <DeleteAccount /> },
// //     { path: "/download", element: <DownloadApk /> },
// //     { path: "/about", element: <QuicKeePage /> },
// //     { path: "/user", element: <UserDetails /> },
// //   ]);

// //   return <div>{routes}</div>;
// // }

// function App() {
//   const dispatch = useDispatch();
//   const { userData, socketIOmessages } = useAppSelector(selectKitchen);
//   const [shouldReconnect, setShouldReconnect] = useState(true);

//   useEffect(() => {
//     setupAxiosInterceptors(dispatch);
//   }, [dispatch]);

//   // ✅ Only run socket.io when userData?.Id is available
//   const { isConnected } = userData?.Id
//     ? UseSocketIO(userData?.Id, shouldReconnect)
//     : { isConnected: false };

//   useEffect(() => {
//     if (!isConnected && userData?.Id) {
//       const retryTimeout = setTimeout(() => {
//         setShouldReconnect(true);
//       }, 2000);
//       return () => clearTimeout(retryTimeout);
//     }
//   }, [isConnected, userData?.Id]);

//   useEffect(() => {
//     if (isConnected) {
//       setShouldReconnect(false);
//     }
//   }, [isConnected]);

//   useEffect(() => {
//     if (socketIOmessages?.length > 0) {
//       console.log("socket messages: ", ...socketIOmessages);
//     }

//     if (userData) {
//       const fetch = setInterval(() => {
//         dispatch(AdminGetAllKitchen());
//         dispatch(AdminGetAllUniversity());
//         dispatch(AdminGetAllAmount());
//         dispatch(AdminGetMonthlyRev());
//         dispatch(AdminGetWeeklyAmount());
//         dispatch(AdminGetDailyRev());

//         // QuicKee Finance Per Month
//         const monthNum = new Date().getMonth() + 1;
//         let monthStr = monthNum < 10 ? "0" + monthNum : monthNum;
//         const month = new Date().getFullYear().toString() + "-" + monthStr;
//         dispatch(GetAllAndGroupByDayInMonth(userData?.KitchenEmail, month));

//         dispatch(AdminGetAllUsers());
//         dispatch(AdminGetTodayUsers());
//         dispatch(GetKitchenOrders(userData?.KitchenEmail));
//       }, 5000);

//       return () => clearInterval(fetch);
//     }
//   }, [socketIOmessages, userData]);

//   const routes = useRoutes([
//     { path: "/", element: <Signup /> },
//     { path: "/signIn", element: <SignIn /> },
//     { path: "/home", element: <Home /> },
//     { path: "/forgotPassword", element: <ForgotPassword /> },
//     { path: "/resetPassword/:email", element: <ResetPassword /> },
//     { path: "/verifyEmail", element: <VerifyEmail /> },
//     { path: "/resendEmail", element: <ResendCode /> },
//     { path: "/quildSignup", element: <AdminSignup /> },
//     { path: "/quildSignin", element: <AdminSignin /> },
//     { path: "/quildBoard", element: <AdminBoard /> },
//     { path: "/deleteaccount", element: <DeleteAccount /> },
//     { path: "/download", element: <DownloadApk /> },
//     { path: "/about", element: <QuicKeePage /> },
//     { path: "/user", element: <UserDetails /> },
//   ]);

//   return <div>{routes}</div>;
// }

import React, { useState, useEffect } from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import ForgotPassword from "./MainCode/ForgotPassword/ForgotPassword";
import ResetPassword from "./MainCode/ForgotPassword/ResetPassword";
import VerifyEmail from "./MainCode/SideBarLinkPage/VerifyEmail/VerifyEmail";
import ResendCode from "./MainCode/SideBarLinkPage/VerifyEmail/ResendVerifyEmail";
import SignIn from "./MainCode/SignInScreen/SignIn";
import Signup from "./MainCode/SignUpScreen/Signup";
import Home from "./MainCode/HomeScreen/Home";
import {
  AdminGetAllKitchen,
  AdminGetAllUniversity,
  AdminGetAllAmount,
  AdminGetMonthlyRev,
  AdminGetWeeklyAmount,
  AdminGetDailyRev,
  GetAllAndGroupByDayInMonth,
  GetKitchenOrders,
  AdminGetAllUsers,
  AdminGetTodayUsers,
} from "./Features/kitchenSlice";
import { useDispatch } from "react-redux";
import { setupAxiosInterceptors } from "../src/Features/utils";
import AdminSignup from "./MainCode/AdminDashboard/AdminSignup/adminSignup";
import AdminSignin from "./MainCode/AdminDashboard/AdminSignin/adminSignin";
import AdminBoard from "./MainCode/AdminDashboard/AdminBoard/adminBoard";
import DeleteAccount from "./MainCode/DeleteAccount/deleteAccount";
import DownloadApk from "./MainCode/DownloadApp/DownloadApk";
import QuicKeePage from "./MainCode/QuicKee/QuickeePage";
import { selectKitchen, useAppSelector } from "./Store/store";
import UseSocketIO from "./UseSocketIO";
import UserDetails from "./MainCode/AdminDashboard/AdminBoard/Others/user-details";

const App = () => {
  const dispatch = useDispatch();
  const { userData, socketIOmessages } = useAppSelector(selectKitchen);
  const [shouldReconnect, setShouldReconnect] = useState(true);
  const { isConnected } = UseSocketIO(userData?.Id, shouldReconnect);

  useEffect(() => {
    // Setting up Axios interceptors
    setupAxiosInterceptors(dispatch);

    return () => {
      // Cleanup if needed
    };
  }, [dispatch]);

  useEffect(() => {
    if (isConnected) {
      setShouldReconnect(false);
    }
  }, [isConnected]);

  useEffect(() => {
    // Fetching data at regular intervals
    const fetch = () => {
      if (userData) {
        //For Registered Kitchen
        dispatch(AdminGetAllKitchen());

        //For the University Card
        dispatch(AdminGetAllUniversity());

        //For the table
        dispatch(AdminGetAllAmount());

        //QuicKee Finances Per Month
        dispatch(AdminGetMonthlyRev());

        //QuicKee Finance Per Week
        dispatch(AdminGetWeeklyAmount());

        //QuicKee Finance Per Day
        dispatch(AdminGetDailyRev());

        // GetAllAndGroupByDayInMonth
        const monthNum = new Date().getMonth() + 1;
        let monthStr = monthNum < 10 ? "0" + monthNum : monthNum;
        const month = new Date().getFullYear().toString() + "-" + monthStr;
        dispatch(GetAllAndGroupByDayInMonth(userData?.KitchenEmail, month));

        //QuicKee Total Users
        dispatch(AdminGetAllUsers());

        //QuicKee Daily Users
        dispatch(AdminGetTodayUsers());

        dispatch(GetKitchenOrders(userData?.KitchenEmail));
      }
    };

    return () => clearInterval(fetch);
  }, [socketIOmessages, userData, dispatch]);

  // Routing setup
  const routes = useRoutes([
    { path: "/", element: <Signup /> },
    { path: "/signIn", element: <SignIn /> },
    { path: "/home", element: <Home /> },
    { path: "/forgotPassword", element: <ForgotPassword /> },
    { path: "/resetPassword/:email", element: <ResetPassword /> },
    { path: "/verifyEmail", element: <VerifyEmail /> },
    { path: "/resendEmail", element: <ResendCode /> },
    { path: "/quildSignup", element: <AdminSignup /> },
    { path: "/quildSignin", element: <AdminSignin /> },
    { path: "/quildBoard", element: <AdminBoard /> },
    { path: "/deleteaccount", element: <DeleteAccount /> },
    { path: "/download", element: <DownloadApk /> },
    { path: "/about", element: <QuicKeePage /> },
    { path: "/user", element: <UserDetails /> },
  ]);

  return <div>{routes}</div>;
};

export default App;
