// import React, { useState, useEffect } from "react";
// import { Card, Form, Button, message, Modal, Input, Table } from "antd";
// import {
//   UploadOutlined,
//   PlusOutlined,
//   EyeOutlined,
//   DeleteOutlined,
// } from "@ant-design/icons";
// import {
//   AddStaff,
//   UploadImage,
//   DeleteStaff,
//   GetAllStaffs,
//   setStaff,
// } from "../../../Features/kitchenSlice";
// import {
//   selectKitchen,
//   useAppSelector,
//   useAppDispatch,
// } from "../../../Store/store";

// function Settings() {
//   const [form] = Form.useForm();
//   const dispatch = useAppDispatch();
//   const { userData, allStaffs, staff } = useAppSelector(selectKitchen);
//   const [addStaffModalVisible, setAddStaffModalVisible] = useState(false);
//   const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
//     useState(false);
//   const [staffShowPasswords, setStaffShowPasswords] = useState([]);
//   const [uploadImageModalVisible, setUploadImageModalVisible] = useState(false);
//   const [formData, setFormData] = useState({
//     FirstName: "",
//     KitchenId: userData.Id,
//     LastName: "",
//     Email: "",
//     Password: "",
//     Phone: "",
//     University: userData.University,
//     Role: "basic",
//   });

//   useEffect(() => {
//     if (!allStaffs) {
//       dispatch(GetAllStaffs(userData?.KitchenEmail));
//     }
//   }, [allStaffs, dispatch, userData]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleUpload = async () => {
//     const fileInput = document.querySelector('input[type="file"]');
//     if (fileInput.files[0]) {
//       const formData = new FormData();
//       formData.append("image", fileInput.files[0]);
//       formData.append("KitchenId", userData.Id);

//       setUploadImageModalVisible(false);
//       dispatch(UploadImage(formData));
//     } else {
//       message.error("Please select an image to upload.");
//     }
//   };

//   const handleAddStaff = () => {
//     dispatch(AddStaff(formData, userData?.KitchenEmail));
//     setAddStaffModalVisible(false);
//   };

//   const handleDeleteStaff = () => {
//     dispatch(DeleteStaff(staff.Email, userData?.KitchenEmail));
//     setDeleteConfirmationVisible(false);
//   };

//   const togglePasswordVisibility = (index) => {
//     const updatedShowPasswords = [...staffShowPasswords];
//     updatedShowPasswords[index] = !updatedShowPasswords[index];
//     setStaffShowPasswords(updatedShowPasswords);
//   };

//   if (userData?.Role === "basic") {
//     return (
//       <div className="mt-8 flex justify-center">
//         <Card title="Settings" className="w-full max-w-2xl">
//           <p>You do not have permission to access this page.</p>
//         </Card>
//       </div>
//     );
//   }

//   return (
//     <div className="p-4">
//       {/* Profile Picture Upload */}
//       <div className="mb-6">
//         <h2 className="text-xl font-semibold mb-2">Upload Profile Picture</h2>
//         <Upload
//           customRequest={handleUpload}
//           showUploadList={false}
//           accept="image/*"
//         >
//           <Button icon={<UploadOutlined />}>Click to Upload</Button>
//         </Upload>
//       </div>

//       {/* Add New Staff */}
//       <div className="mb-6 flex justify-between items-center">
//         <Button
//           type="primary"
//           icon={<PlusOutlined />}
//           onClick={() => setIsModalVisible(true)}
//         >
//           Add New Staff
//         </Button>
//       </div>

//       <Table
//         dataSource={staffList}
//         columns={columns}
//         rowKey={(record) => record.Id}
//         pagination={{ pageSize: 5 }}
//       />

//       {/* Add Staff Modal */}
//       <Modal
//         title="Add New Staff"
//         open={isModalVisible}
//         onCancel={() => setIsModalVisible(false)}
//         footer={null}
//       >
//         <Form form={form} onFinish={handleAddStaff} layout="vertical">
//           <Form.Item
//             label="First Name"
//             name="FirstName"
//             rules={[{ required: true, message: "Please enter staff name!" }]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Last Name"
//             name="LastName"
//             rules={[{ required: true, message: "Please enter staff surname!" }]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Email"
//             name="Email"
//             rules={[
//               {
//                 required: true,
//                 type: "email",
//                 message: "Please enter a valid email!",
//               },
//             ]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Phone"
//             name="Phone"
//             rules={[{ required: true, message: "Please enter staff phone!" }]}
//           >
//             <Input />
//           </Form.Item>
//           <Form.Item
//             label="Password"
//             name="Password"
//             rules={[
//               { required: true, message: "Please enter staff password!" },
//             ]}
//           >
//             <Input.Password />
//           </Form.Item>
//           <Form.Item>
//             <Button type="primary" htmlType="submit" block>
//               Add Staff
//             </Button>
//           </Form.Item>
//         </Form>
//       </Modal>
//     </div>
//   );
// }

// export default Settings;

"use client";

import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Table,
  Row,
  Col,
  Divider,
} from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import {
  AddStaff,
  GetAllStaffs,
  UploadImage,
} from "../../../Features/kitchenSlice";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../../Store/store";
import { Eye, EyeSlash } from "phosphor-react";

function Settings() {
  const dispatch = useAppDispatch();
  const { userData, allStaffs } = useAppSelector(selectKitchen) || {};

  const [staffList, setStaffList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [profilePicture, setProfilePicture] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (allStaffs) {
      setStaffList(allStaffs);
    } else if (userData?.KitchenEmail) {
      dispatch(GetAllStaffs(userData.KitchenEmail));
    }
  }, [allStaffs, dispatch, userData]);

  const handleUploadRequest = async ({ file, onSuccess }) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("KitchenId", userData?.Id?.toString() || "");

    try {
      dispatch(UploadImage(formData)); // Trigger the image upload action
      setProfilePicture(file); // Update state for preview
      onSuccess && onSuccess("ok"); // Signal success to the Upload component
    } catch (error) {
      message.error("Image upload failed.");
    }
  };

  const handleAddStaff = async (values) => {
    const staffData = {
      ...values,
      KitchenId: userData?.Id,
      University: userData?.University,
      Role: "basic",
    };

    dispatch(AddStaff(staffData, userData?.KitchenEmail));
    form.resetFields();
    setIsModalVisible(false);
    message.success("Staff added successfully!");
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
  ];

  return (
    <div className="p-4">
      {/* Profile Picture Upload */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Upload Profile Picture</h2>
        <Upload
          customRequest={handleUploadRequest}
          showUploadList={false}
          accept="image/*"
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>

      {/* Add New Staff */}
      <div className="mb-6 flex justify-between items-center">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
        >
          Add New Staff
        </Button>
      </div>

      <Table
        dataSource={staffList}
        columns={columns}
        rowKey={(record) => record.Id}
        pagination={{ pageSize: 5 }}
      />

      {/* Add Staff Modal */}
      <Modal
        title="Add New Staff"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
      >
        <Form form={form} onFinish={handleAddStaff} layout="vertical">
          {/* Name Fields */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="FirstName"
                rules={[{ required: true, message: "Enter first name" }]}
              >
                <Input
                  placeholder="John"
                  style={{ width: "100%", height: "30px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="LastName"
                rules={[{ required: true, message: "Enter last name" }]}
              >
                <Input
                  placeholder="Doe"
                  style={{ width: "100%", height: "30px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Contact Details */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Email Address"
                name="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Enter a valid email",
                  },
                ]}
              >
                <Input
                  placeholder="example@email.com"
                  style={{ width: "100%", height: "30px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phone Number"
                name="Phone"
                rules={[{ required: true, message: "Enter phone number" }]}
              >
                <Input
                  placeholder="+234 812 345 6789"
                  style={{ width: "100%", height: "30px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Password Field */}
          <Form.Item
            label="Set Password"
            name="Password"
            rules={[{ required: true, message: "Enter a strong password" }]}
          >
            <Input
              placeholder="••••••••"
              style={{ width: "100%", height: "30px" }}
              type={showPassword ? "text" : "password"}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-1/3 right-4 transform -translate-y-1/2 text-gray-500"
            >
              {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
            </button>
          </Form.Item>

          <Divider />

          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit" block size="large">
              Add Staff Member
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Settings;
