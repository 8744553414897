// import { useEffect, useRef, useState } from "react";
// import { io } from "socket.io-client";
// import { useDispatch } from "react-redux";
// import { setSocketIOmessages } from "./Features/kitchenSlice";

// const socket = io("https://srv516319.hstgr.cloud:85", {
//   // query: { userId: "acZm32Qgh" },
//   query: { userId },
//   transports: ["websocket", "polling", "webtransport"],
//   reconnection: true,
//   reconnectionAttempts: Infinity,
//   reconnectionDelay: 1000,
//   reconnectionDelayMax: 5000,
//   timeout: 5000,
// });

// const UseSocketIO = (userId, shouldReconnect) => {
//   const [isConnected, setIsConnected] = useState(false);
//   const dispatch = useDispatch();
//   const socketRef = useRef(null);
//   const [connectionAttempts, setConnectionAttempts] = useState(0);
//   const MAX_CONNECTION_ATTEMPTS = Infinity;

//   useEffect(() => {
//     if (!userId) return;

//     console.log("Connecting socket with userId:", userId);

//     socketRef.current = socket;

//     socket.on("connect", () => {
//       setIsConnected(true);
//       setConnectionAttempts(0);
//       socket.emit("userId: ", { userId: userId });
//       //console.log("Connected to server");
//     });

//     socket.on("transaction-success", (data) => {
//       //console.log("Received data from socketIO: ", data);
//       dispatch(setSocketIOmessages(data));
//     });

//     socket.on("connect_error", (err) => {
//       // console.error("Connection error:", err);
//       // retryConnection(socket);
//     });

//     socket.on("disconnect", (reason) => {
//       setIsConnected(false);
//       //console.log("Disconnected:", reason);
//       // retryConnection(socket);
//     });

//     socket.on("reconnecting", (reason) => {
//       //console.log("Reconnecting...", reason);
//     });

//     // Function to retry connection
//     const retryConnection = (socket) => {
//       if (connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
//         //console.log(`Retrying connection attempt ${connectionAttempts + 1}...`);
//         setConnectionAttempts((prevAttempts) => prevAttempts + 1);
//         setTimeout(() => {
//           if (shouldReconnect) {
//             //console.log("reconnection triggered from App.js");
//             socket.connect(); // Retry connection after a delay
//           }
//         }, 2000); // Adjust the delay as needed
//       } else {
//         //console.error("Exceeded maximum connection attempts.");
//       }
//     };

//     return () => {
//       socket.disconnect();
//     };
//   }, [userId, dispatch, shouldReconnect, MAX_CONNECTION_ATTEMPTS]);

//   return { isConnected };
// };

// export default UseSocketIO;

// import { useEffect, useRef, useState } from "react";
// import { io } from "socket.io-client";
// import { useDispatch } from "react-redux";
// import { setSocketIOmessages } from "./Features/kitchenSlice";

// const UseSocketIO = (userId, shouldReconnect) => {
//   const [isConnected, setIsConnected] = useState(false);
//   const dispatch = useDispatch();
//   const socketRef = useRef(null);
//   const [connectionAttempts, setConnectionAttempts] = useState(0);
//   const MAX_CONNECTION_ATTEMPTS = 5; // Set a maximum number of reconnection attempts

//   useEffect(() => {
//     if (!userId) {
//       return;
//     }

//     // Initialize socket connection only when userId is available
//     console.log("Connecting socket with userId:", userId);
//     socketRef.current = io("https://srv516319.hstgr.cloud:85", {
//       query: { userId },
//       transports: ["websocket", "polling", "webtransport"],
//       reconnection: true,
//       reconnectionAttempts: Infinity,
//       reconnectionDelay: 1000,
//       reconnectionDelayMax: 5000,
//       timeout: 5000,
//     });

//     // Socket event listeners
//     socketRef.current.on("connect", () => {
//       setIsConnected(true);
//       setConnectionAttempts(0);
//       socketRef.current.emit("userId: ", { userId });
//       console.log("Connected to server");
//     });

//     socketRef.current.on("transaction-success", (data) => {
//       dispatch(setSocketIOmessages(data));
//     });

//     socketRef.current.on("connect_error", (err) => {
//       console.error("Connection error:", err);
//       retryConnection();
//     });

//     socketRef.current.on("disconnect", (reason) => {
//       setIsConnected(false);
//       console.log("Disconnected:", reason);
//       retryConnection();
//     });

//     socketRef.current.on("reconnecting", (reason) => {
//       console.log("Reconnecting...", reason);
//     });

//     // Retry connection logic
//     const retryConnection = () => {
//       if (connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
//         setConnectionAttempts((prev) => prev + 1);
//         setTimeout(() => {
//           if (shouldReconnect) {
//             console.log("Reconnection triggered from App.js");
//             socketRef.current.connect();
//           }
//         }, 2000);
//       } else {
//         console.error("Exceeded maximum connection attempts.");
//       }
//     };

//     // Cleanup socket on component unmount or when userId changes
//     return () => {
//       if (socketRef.current) {
//         socketRef.current.disconnect();
//         socketRef.current = null;
//       }
//     };
//   }, [userId, dispatch, shouldReconnect, connectionAttempts]);

//   return { isConnected };
// };

// export default UseSocketIO;

import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { setSocketIOmessages } from "./Features/kitchenSlice";

const UseSocketIO = (userId, shouldReconnect) => {
  const [isConnected, setIsConnected] = useState(false);
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const [connectionAttempts, setConnectionAttempts] = useState(0);
  const MAX_CONNECTION_ATTEMPTS = 5;
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!userId) {
      console.warn("No userId provided, skipping socket connection.");
      return;
    }

    console.log("Attempting to connect socket with userId:", userId);

    socketRef.current = io("https://srv516319.hstgr.cloud:85", {
      query: { userId }, // Ensure the userId is passed correctly
      transports: ["websocket", "polling"],
      reconnection: true,
      reconnectionAttempts: MAX_CONNECTION_ATTEMPTS,
      reconnectionDelay: 2000,
      timeout: 10000, // Increase timeout in case of slow server response
    });

    // Handle successful connection
    socketRef.current.on("connect", () => {
      setIsConnected(true);
      setConnectionAttempts(0);
      console.log("Connected to server with ID:", userId);
      socketRef.current.emit("register-user", { userId });
    });

    // Handle incoming messages
    socketRef.current.on("transaction-success", (data) => {
      console.log("Received transaction-success:", data);
      dispatch(setSocketIOmessages(data));
    });

    // Handle connection errors
    socketRef.current.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
      retryConnection();
    });

    // Handle disconnection
    // socketRef.current.on("disconnect", (reason) => {
    //   setIsConnected(false);
    //   console.warn("Socket disconnected:", reason);
    //   if (reason !== "io client disconnect") {
    //     retryConnection();
    //   }
    // });

    // Function to retry connection
    const retryConnection = () => {
      if (connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
        setConnectionAttempts((prev) => prev + 1);
        console.log(
          `Retrying connection (${
            connectionAttempts + 1
          }/${MAX_CONNECTION_ATTEMPTS})...`
        );
        setTimeout(() => {
          if (shouldReconnect && socketRef.current) {
            socketRef.current.connect();
          }
        }, 3000);
      } else {
        console.error("Max reconnection attempts reached.");
      }
    };

    // Cleanup function
    return () => {
      if (socketRef.current) {
        console.log("Cleaning up socket connection...");
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [userId, dispatch, shouldReconnect, connectionAttempts]);

  useEffect(() => {
    if (!shouldReconnect || !userId) return;

    const newSocket = io("https://srv516319.hstgr.cloud:85", {
      query: { userId },
    });

    newSocket.on("connect", () => setIsConnected(true));
    newSocket.on("disconnect", () => setIsConnected(false));

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [userId, shouldReconnect]);

  return { isConnected, socket };
};

export default UseSocketIO;
