import React, { useState, useEffect } from "react";
import { GetReviews, NotifyEveryone } from "../Features/kitchenSlice";
import { selectKitchen, useAppSelector, useAppDispatch } from "../Store/store";
import { Bell, Mail } from "lucide-react";
import { Modal, Button, Input } from "antd";

function Header() {
  const dispatch = useAppDispatch();
  const { userData, reviews } = useAppSelector(selectKitchen);

  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [totalAgreeCount, setTotalAgreeCount] = useState(0);
  const [totalDisagreeCount, setTotalDisagreeCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (!reviews) {
      const isBasicStaff = userData?.Role === "basic";
      const kitchenId = isBasicStaff ? userData?.KitchenId : userData?.Id;
      dispatch(GetReviews(kitchenId));
    }
  }, [dispatch, reviews, userData]);

  useEffect(() => {
    if (reviews) {
      const totalAgree = reviews.reduce(
        (total, review) => total + parseInt(review.AgreeCount),
        0
      );
      const totalDisagree = reviews.reduce(
        (total, review) => total + parseInt(review.DisagreeCount),
        0
      );
      setTotalAgreeCount(totalAgree);
      setTotalDisagreeCount(totalDisagree);
    }
  }, [reviews]);

  const kitchenRating =
    totalAgreeCount + totalDisagreeCount
      ? (totalAgreeCount / (totalAgreeCount + totalDisagreeCount)) * 5
      : 0.0;

  const handleBellIconClick = () => setIsModalVisible(true);
  const handleModalOk = () => {
    const isBasicStaff = userData.Role === "basic";
    const kitchenId = isBasicStaff ? userData.KitchenId : userData.Id;

    const payload = {
      KitchenId: kitchenId,
      Title: notificationTitle,
      UserId: "abcd",
      Message: notificationMessage,
    };

    dispatch(NotifyEveryone(payload));
    setIsModalVisible(false);
  };

  // Function to get the first letter of Kitchen Name for Avatar fallback
  const getAvatarLetter = () => {
    return userData?.KitchenName
      ? userData.KitchenName.charAt(0).toUpperCase()
      : "Q";
  };

  // Handle modal Cancel button click
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="bg-white p-3 rounded-xl flex items-center justify-between w-full">
      {/* Kitchen Logo / Avatar */}
      <div className="flex items-center space-x-4">
        {userData?.KitchenImage ? (
          <img
            src={`https://drive.google.com/thumbnail?id=${userData?.KitchenImage}`}
            alt="Kitchen Logo"
            className="w-16 h-16 rounded-full border object-cover"
          />
        ) : (
          <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center text-white text-2xl font-bold">
            {getAvatarLetter()}
          </div>
        )}

        {/* Kitchen Name */}
        <h2 className="text-lg font-semibold mt-2 mb-2">
          {userData?.Role === "basic"
            ? `${userData.FirstName} ${userData.LastName} (Staff)`
            : userData?.KitchenName || "Loading..."}
        </h2>
      </div>

      {/* Rating and Notifications */}
      <div className="flex items-center space-x-4">
        <div className="flex items-center">
          <span className="text-yellow-400 text-lg font-semibold mt-1.4">
            {kitchenRating.toFixed(1)}
          </span>
          <span className="ml-2 text-gray-600">⭐</span>
        </div>

        <button className="relative p-2 rounded-full hover:bg-gray-200">
          <Mail className="w-6 h-6 text-gray-600" />
          {reviews?.length > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs px-1.5 py-0.5 rounded-full">
              {reviews.length}
            </span>
          )}
        </button>

        <button
          onClick={handleBellIconClick}
          className="p-2 rounded-full hover:bg-gray-200"
        >
          <Bell className="w-6 h-6 text-gray-600" />
        </button>
      </div>

      {/* Notification Modal */}
      {/* {isModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-80">
            <h3 className="text-lg font-semibold mb-2">Send Notification</h3>
            <label className="text-sm font-medium">Title:</label>
            <input
              className="w-full p-2 border rounded-md mt-1"
              value={notificationTitle}
              onChange={(e) => setNotificationTitle(e.target.value)}
            />
            <label className="text-sm font-medium mt-3 block">Message:</label>
            <textarea
              className="w-full p-2 border rounded-md mt-1"
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
            <div className="flex justify-end mt-4 space-x-2">
              <button
                onClick={() => setIsModalVisible(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleModalOk}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )} */}

      <Modal
        title="Send a general notification"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleModalOk}>
            OK
          </Button>,
        ]}
      >
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "1%", flexDirection: "row" }}>
            <label htmlFor="notificationTitle">Title:</label>
          </div>
          <Input
            id="notificationTitle"
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
            style={{ width: "100%", height: "30px" }}
          />
          <div style={{ marginBottom: "16px" }}>
            <label htmlFor="notificationMessage">Message:</label>
            <Input.TextArea
              id="notificationMessage"
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
