import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Descriptions,
  Divider,
  List,
  Button,
  Modal,
  message,
  Tag,
  Input,
  Collapse,
  Select,
  notification,
} from "antd";
import moment from "moment";
import {
  GetKitchenOrders,
  SendNotification,
  SendExtraNotification,
  setLastProcessedOrder,
} from "../../../Features/kitchenSlice";
import { ChatCircleDots, Printer } from "phosphor-react";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../../Store/store";
const { Panel } = Collapse;
const { Option } = Select;

const Orders = () => {
  const dispatch = useAppDispatch();
  const { userData, auth, orders, lastProcessedOrder } =
    useAppSelector(selectKitchen);

  const [currentOrderIndex, setCurrentOrderIndex] = useState(0);
  const [attendedOrders, setAttendedOrders] = useState([]);
  const [doneAndPackagedOrders, setDoneAndPackagedOrders] = useState([]);
  const [isChatModalVisible, setChatModalVisible] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [orderNumber, setOrderNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [attendedFilter, setAttendedFilter] = useState(null);
  const audioRef = useRef(null);
  const animationRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);

  // Modal for Order Description
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  // To trunctuate the order description
  const maxLength = 10;

  // Functions for the modal
  const showDescriptionModal = () => setIsDescriptionVisible(true);
  const handleDescriptionCancel = () => setIsDescriptionVisible(false);

  const isElizadeUniversity = () => {
    return (
      userData?.University === "Elizade" ||
      userData?.University === "Elizade University"
    );
  };

  useEffect(() => {
    if (!orders) {
      dispatch(GetKitchenOrders(userData?.KitchenEmail));
    }
  }, []);

  const handleAttendedFilterChange = (value) => {
    setAttendedFilter(value);
  };

  const currentDate = moment();
  const today = currentDate.date();

  const filteredOrders = orders?.filter((order) => {
    const orderDate = moment(order.CreatedAt);
    return today === orderDate.date() && order.IsPaid === true;
  });

  var currentOrder;
  if (orders) {
    currentOrder = filteredOrders[currentOrderIndex];
  }

  const handleChatIconClick = () => {
    setChatModalVisible(true);
  };

  const handleModalCancel = () => {
    setChatModalVisible(false);
  };

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Modal to show Items in an order
  const showModal = (items) => {
    setSelectedOrderItems(items);
    setIsModalVisible(true);
  };

  // Function to close the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSendMessage = async (Order) => {
    const updatedOrder = {
      ...Order,
      IsAttended: false,
    };

    let notificationData;

    if (userData && userData.Role === "basic") {
      notificationData = {
        KitchenId: userData.KitchenId,
        Title: `${userData.FirstName} (staff)`,
        UserId: updatedOrder.UserId,
        Message: messageInput,
        OrderId: updatedOrder.TrxRef,
      };
    } else {
      notificationData = {
        KitchenId: userData.Id,
        Title: userData.KitchenName,
        UserId: updatedOrder.UserId,
        Message: messageInput,
        OrderId: updatedOrder.TrxRef,
      };
    }

    dispatch(SendExtraNotification(notificationData, auth));
    // const notificationResponse = dispatch(
    //   SendExtraNotification(notificationData, auth)
    // );

    // if (notificationResponse && notificationResponse.code === 200) {
    //   message.success("Notification sent successfully");
    //   // Fetch updated orders from the server
    //   fetchOrders();
    // }
    setChatModalVisible(false);
  };

  const fetchOrders = async () => {
    if (userData && Array.isArray(orders)) {
      dispatch(GetKitchenOrders(userData?.KitchenEmail));

      const sortedMenus = [...orders].sort((a, b) => {
        return moment(b.CreatedAt).valueOf() - moment(a.CreatedAt).valueOf();
      });

      const filteredOrdersForToday = sortedMenus.filter((order) => {
        const orderDate = moment(order.CreatedAt);
        return today === orderDate.date();
      });

      const updatedOrders = filteredOrdersForToday.map((order, index) => ({
        ...order,
        OrderNumber: index + 1,
      }));

      setOrderNumber(orderNumber + orders.length);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchOrders, 2000);
    return () => clearInterval(intervalId);
  }, [auth]);

  useEffect(() => {
    if (lastProcessedOrder?.IsAttended === true) {
      setDoneAndPackagedOrders((prevDoneAndPackagedOrders) => [
        ...prevDoneAndPackagedOrders,
        lastProcessedOrder.OrderId,
      ]);
      setAttendedOrders((OrderAttended) => [
        ...OrderAttended,
        lastProcessedOrder.OrderId,
      ]);
      dispatch(setLastProcessedOrder(null));
    }
  }, [lastProcessedOrder]);

  const handleDoneAndPackagedButtonClick = async (order) => {
    if (order.IsAttended === true) {
      return;
    }

    // Determine message based on whether it's Elizade University
    let message;
    if (isElizadeUniversity()) {
      message = order.IsTakeAway
        ? `Your order with ID ${order.TrxRef} is ready for takeaway! Please pick it up with your cooler at the cafeteria.`
        : `Your order with ID ${order.TrxRef} is ready to be ate at the cafeteria! Please come to the cafeteria to enjoy your meal.`;
    } else {
      message = order.IsTakeAway
        ? `Using Takeaway. Your order with ID ${order.TrxRef} is ready for collection.`
        : `No Takeaway. Your order with ID ${order.TrxRef} is ready for collection.`;
    }

    // Prepare notification data
    let notificationData;
    if (userData && userData.Role === "basic") {
      notificationData = {
        KitchenId: userData.KitchenId,
        Title: `${userData.FirstName} (staff)`,
        UserId: order.UserId,
        Message: message,
        OrderId: order.TrxRef,
      };
    } else {
      notificationData = {
        KitchenId: userData.Id,
        Title: `${userData.KitchenName} - Order Done and Packaged`,
        UserId: order.UserId,
        Message: message,
        OrderId: order.TrxRef,
      };
    }

    // Send the notification
    dispatch(SendNotification(notificationData));
  };

  const ordersPerPage = 3;

  const handleNextPage = () => {
    if (currentOrderIndex + ordersPerPage < filteredOrders.length) {
      setCurrentOrderIndex(currentOrderIndex + ordersPerPage);
    }
  };

  const handlePrevPage = () => {
    if (currentOrderIndex > 0) {
      setCurrentOrderIndex(currentOrderIndex - ordersPerPage);
    }
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {});
    }
  };

  const animateAudio = () => {
    playAudio();
    animationRef.current = requestAnimationFrame(animateAudio);
  };

  useEffect(() => {
    const storedLatestOrderTimestamp = localStorage.getItem(
      "latestOrderTimestamp"
    );
    const latestOrderTimestamp = storedLatestOrderTimestamp
      ? parseInt(storedLatestOrderTimestamp)
      : 0;

    // Sort the orders by creation time in descending order
    var currentOrderC;
    if (filteredOrders) {
      const sortedOrders = [...filteredOrders].sort(
        (a, b) => moment(b.CreatedAt).unix() - moment(a.CreatedAt).unix()
      );
      currentOrderC = sortedOrders[currentOrderIndex];
    }

    const isNewOrder =
      currentOrderC &&
      moment(currentOrderC.CreatedAt).unix() > latestOrderTimestamp;

    if (isNewOrder) {
      // Trigger Notification
      notification.info({
        message: "New Order!",
        description: `Order #${currentOrderC.TrxRef} has been received.`,
        duration: 0,
        onClose: () => {
          // Stop audio and animation when the notification is closed
          if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
          }
          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current);
          }
        },
      });

      const audio = new Audio("/message_tone.mp3");
      audio.loop = true; // Set the loop property to true

      // Assign the audio element to the ref
      audioRef.current = audio;

      // Start continuous audio playback
      animateAudio();

      // Update LocalStorage with the latest timestamp
      localStorage.setItem(
        "latestOrderTimestamp",
        moment(currentOrderC.CreatedAt).unix().toString()
      );
    }
  }, [filteredOrders, currentOrderIndex]);

  const formatOrderDate = (dateString) => {
    // Create a new Date object from the date string
    const orderDate = new Date(dateString);

    // Define options for formatting the date
    const options = {
      weekday: "long", // Full name of the day of the week (e.g., "Friday")
      month: "long", // Full name of the month (e.g., "May")
      day: "numeric", // Numeric day of the month (e.g., 10th)
      year: "numeric", // Year (e.g., 2024)
      hour: "numeric", // Hour in 12-hour format (e.g., 3)
      minute: "numeric", // Minutes (e.g., 21)
      second: "numeric", // Seconds (e.g., 4)
      hour12: true, // 12-hour format with am/pm
    };

    // Format the date using the Intl.DateTimeFormat API
    const formattedDate = orderDate.toLocaleString("en-US", options);

    return formattedDate;
  };

  const renderOrderDate = (date) => {
    const orderDate = new Date(date);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: "UTC", // Specify time zone to UTC to standardize the output
    };

    return orderDate.toLocaleString(undefined, options);
  };

  const handlePrint = (order) => {
    if (order) {
      const foodItemsList = order.Items.map(
        (foodItem, index) =>
          `<li key=${index}>${foodItem.Name} x${foodItem.Scoops} (₦${foodItem.Price})</li>`
      ).join("");

      const contentToPrint = `
        <h1>Order ID: ${order.TrxRef}</h1>
        <p>Date: ${renderOrderDate(order.CreatedAt)}</p>
        <h2>Food Items:</h2>
        <ul>
          ${foodItemsList}
        </ul>
      `;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>
            ${contentToPrint}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <div className="container mx-auto p-4">
      {/* Search and Filter Section */}
      <div className="flex justify-between items-center gap-4 mb-6">
        <Input.Search
          placeholder="Search Order ID"
          value={searchQuery}
          onChange={handleSearchChange}
          className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
          allowClear
        />
        <Select
          style={{ width: "150px" }}
          placeholder="Filter"
          onChange={handleAttendedFilterChange}
        >
          <Select.Option value="attended">Attended</Select.Option>
          <Select.Option value="unattended">Unattended</Select.Option>
        </Select>
      </div>

      {/* Order Cards */}
      {filteredOrders?.length > 0 ? (
        <div className="space-y-6 lg:ml-60">
          {filteredOrders
            ?.slice(currentOrderIndex, currentOrderIndex + ordersPerPage)
            ?.filter(
              (order) =>
                order.TrxRef.toString().includes(searchQuery) &&
                (attendedFilter === null ||
                  order.IsAttended === (attendedFilter === "attended"))
            )
            .map((order) => (
              <div
                key={order.TrxRef}
                className="bg-white shadow-lg rounded-xl p-6 flex flex-col md:flex-row justify-between items-start lg:items-center"
              >
                {/* Order Header */}
                <div className="flex flex-col lg:flex-row justify-between w-full lg:w-auto">
                  <div>
                    <h3 className="text-xl font-semibold text-gray-800">
                      Order ID: {order.TrxRef}
                    </h3>
                    <p className="text-gray-500 text-sm">
                      {moment(order.CreatedAt).format("HH:mm A")}
                    </p>
                  </div>
                  <div className="flex items-center gap-6 mt-4 lg:mt-0">
                    <ChatCircleDots
                      size={26}
                      className="text-green-500 cursor-pointer"
                      onClick={handleChatIconClick}
                    />
                    {order.IsAttended && (
                      <span className="px-3 py-1 bg-green-100 text-green-600 rounded-md text-sm">
                        Attended
                      </span>
                    )}
                  </div>
                </div>

                {/* Order Details */}
                <div className="mt-6 lg:mt-0 lg:ml-6 space-y-2">
                  {/* <p className="text-gray-700 text-lg">
                    <span className="font-semibold">Description:</span>{" "}
                    {order.Description}
                  </p> */}
                  <p className="text-gray-700 text-lg">
                    <span className="font-semibold">Description:</span>{" "}
                    {order?.Description.length > maxLength ? (
                      <>
                        {order?.Description.substring(0, maxLength)}...
                        <button
                          onClick={showDescriptionModal}
                          className="text-blue-500 hover:underline ml-2"
                        >
                          Read more
                        </button>
                      </>
                    ) : (
                      order.Description
                    )}
                  </p>
                  <p className="text-gray-700 text-lg">
                    <span className="font-semibold">Process:</span>{" "}
                    {order.IsTakeAway ? "Takeaway" : "No Takeaway"}
                  </p>
                </div>

                {/* Action Buttons */}
                <div className="flex flex-wrap gap-4 mt-6 lg:mt-0 lg:ml-6 w-full lg:w-auto">
                  <Button
                    type="link"
                    onClick={() => showModal(order.Items)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    View Items
                  </Button>

                  {order.IsAttended === false && (
                    <Button
                      type="primary"
                      onClick={() => handleDoneAndPackagedButtonClick(order)}
                      className="w-full lg:w-auto hover:bg-green-600 transition duration-200"
                    >
                      Mark as Attended
                    </Button>
                  )}
                  {/* <Button
                    type="default"
                    onClick={() => console.log("Print")}
                    className="w-full lg:w-auto hover:bg-gray-100 transition duration-200"
                  >
                    Print
                  </Button> */}
                </div>
                <Modal
                  title="Send this user a message"
                  open={isChatModalVisible}
                  onCancel={handleModalCancel}
                  footer={[
                    <Button key="cancel" onClick={handleModalCancel}>
                      Cancel
                    </Button>,
                    <Button
                      key="send"
                      type="primary"
                      onClick={() => handleSendMessage(order)}
                    >
                      Send
                    </Button>,
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    value={messageInput}
                    onChange={handleInputChange}
                    placeholder="Type your message here..."
                  />
                </Modal>

                <Modal
                  title="Order Items"
                  open={isModalVisible}
                  onCancel={handleCancel}
                  footer={[
                    <Button
                      key="back"
                      onClick={handleCancel}
                      className="w-full lg:w-auto"
                    >
                      Close
                    </Button>,
                  ]}
                  // width={600} // Modal width
                  // className="modal-custom" // Add custom styling
                >
                  <List
                    dataSource={selectedOrderItems}
                    renderItem={(dishItem, index) => (
                      <List.Item key={index}>
                        <div className="flex justify-between w-full">
                          <div
                            style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                          >
                            {dishItem.Name} x{dishItem.Scoops}
                          </div>
                          <div className="text-lg text-gray-700">
                            ₦{dishItem.Price}
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                  <div className="mt-4 p-4 bg-gray-100 rounded-lg flex justify-between items-center text-lg font-semibold">
                    <span className="text-gray-800">Total Amount:</span>
                    <span className="text-green-600">
                      ₦
                      {selectedOrderItems.reduce(
                        (total, item) => total + item.Price,
                        0
                      )}
                    </span>
                  </div>
                </Modal>

                {/* Modal to show full description */}
                <Modal
                  title="Full Description"
                  open={isDescriptionVisible}
                  onCancel={handleDescriptionCancel}
                  footer={null}
                >
                  <p className="text-gray-700 text-lg">{order.Description}</p>
                </Modal>
              </div>
            ))}
        </div>
      ) : (
        <p>No orders available</p>
      )}

      {/* Pagination */}
      {filteredOrders?.length > ordersPerPage && (
        <div className="flex justify-between mt-6">
          <Button
            onClick={handlePrevPage}
            disabled={currentOrderIndex === 0}
            className="w-full lg:w-auto"
          >
            Prev
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={
              currentOrderIndex + ordersPerPage >= filteredOrders.length
            }
            className="w-full lg:w-auto"
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default Orders;

// <Descriptions.Item label="Order Number">
//                         #{filteredOrders.length - index}
//                       </Descriptions.Item>

// Order #{filteredOrders.length - index}
