// import React, { useState, useEffect } from "react";
// import { Card, Table, Tag, Input, Modal, message } from "antd";
// import { Printer } from "phosphor-react";
// import {
//   selectKitchen,
//   useAppSelector,
//   useAppDispatch,
// } from "../../../Store/store";
// import { GetKitchenOrdersHistory } from "../../../Features/kitchenSlice";

// function History() {
//   const dispatch = useAppDispatch();
//   const [searchText, setSearchText] = useState("");
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const { userData, auth, ordersHistory } = useAppSelector(selectKitchen);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(2);
//   const [kitchenOrders, setKitchenOrders] = useState([]);
//   const [filteredKitchenOrders, setFilteredKitchenOrders] = useState([]);

//   useEffect(() => {
//     // if (!ordersHistory) {
//     dispatch(GetKitchenOrdersHistory(userData?.KitchenEmail, currentPage));
//     // }
//   }, [dispatch, userData, currentPage]);

//   const fetchKitchenOrders = async () => {
//     if (ordersHistory) {
//       const paidOrders = ordersHistory?.data?.Orders.filter(
//         (order) => order.IsPaid === true // || order.IsPaid === false
//       );

//       const sortedOrders = paidOrders.sort((a, b) => {
//         const dateA = new Date(a.CreatedAt);
//         const dateB = new Date(b.CreatedAt);
//         return dateB - dateA;
//       });

//       setKitchenOrders(sortedOrders);
//       setFilteredKitchenOrders(sortedOrders);
//     }
//   };

//   useEffect(() => {
//     fetchKitchenOrders();
//   }, [userData, auth, ordersHistory]);

//   const sortOrders = (ordersHistory) => {
//     return ordersHistory.sort((a, b) => {
//       const dateA = new Date(a.CreatedAt);
//       const dateB = new Date(b.CreatedAt);
//       return dateB - dateA;
//     });
//   };

//   const handleSearch = () => {
//     const lowerSearchText = searchText.toLowerCase();
//     const filtered = kitchenOrders.filter((order) =>
//       order.TrxRef.toLowerCase().includes(lowerSearchText)
//     );
//     const sortedFilteredOrders = sortOrders(filtered);
//     setFilteredKitchenOrders(sortedFilteredOrders);
//   };

//   useEffect(() => {
//     if (searchText) {
//       handleSearch();
//     } else {
//       const sortedOrders = sortOrders(kitchenOrders);
//       setFilteredKitchenOrders(sortedOrders);
//     }
//   }, [searchText, kitchenOrders]);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const renderOrderStatus = (orderStatus, isPaid) => {
//     const color = orderStatus === "Attended" ? "green" : "red";
//     const statusTag = <Tag color={color}>{orderStatus}</Tag>;

//     if (isPaid) {
//       return (
//         <>
//           {statusTag}
//           <Tag color="#006400">Paid</Tag>
//         </>
//       );
//     }

//     return (
//       <>
//         {statusTag}
//         <Tag color="#FF0000">Not Paid/Pending</Tag>
//       </>
//     );
//   };

//   const renderOrderDate = (date) => {
//     const orderDate = new Date(date);

//     const options = {
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//       hour: "numeric",
//       minute: "numeric",
//       second: "numeric",
//       hour12: false,
//       timeZone: "UTC", // Specify time zone to UTC to standardize the output
//     };

//     return orderDate.toLocaleString(undefined, options);
//   };

//   const tableColumns = [
//     {
//       title: "Order ID",
//       dataIndex: "TrxRef",
//       key: "TrxRef",
//     },
//     {
//       title: "Food Details",
//       dataIndex: "Items",
//       key: "Items",
//       render: (foodDetails) => {
//         const names = foodDetails.map((item) => item.Name);
//         const namesString = names.join(", ");
//         return (
//           <div>
//             {namesString.length > 10
//               ? namesString.substring(0, 10).trim() + "..."
//               : namesString}
//           </div>
//         );
//         // <ul>
//         //   {foodDetails &&
//         //     foodDetails.map((foodItem, index) => (
//         //       <li key={index}>
//         //         {foodItem.Name} x{foodItem.Scoops} (₦{foodItem.Price})
//         //       </li>
//         //     ))}
//         // </ul>
//       },
//     },
//     {
//       title: "Description",
//       dataIndex: "Description",
//       key: "Description",
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       key: "status",
//       render: (text, record) => renderOrderStatus(text, record.IsPaid),
//     },
//     {
//       title: "Date",
//       dataIndex: "CreatedAt",
//       key: "CreatedAt",
//       render: (text, record) => renderOrderDate(text),
//     },
//     {
//       title: "Total Price",
//       dataIndex: "TotalAmount",
//       key: "TotalAmount",
//       render: (text, record) => `₦${text}`,
//     },
//     {
//       title: "Attended",
//       dataIndex: "IsAttended",
//       key: "IsAttended",
//       render: (isAttended) => (
//         <Tag color={isAttended ? "green" : "red"}>
//           {isAttended ? "Attended" : "Not Attended"}
//         </Tag>
//       ),
//     },
//   ];

//   const handleOrderClick = (order) => {
//     setSelectedOrder(order);
//     setIsModalVisible(true);
//   };

//   const handleModalClose = () => {
//     setIsModalVisible(false);
//   };

//   // const handlePrint = () => {
//   //   if (selectedOrder) {
//   //     const foodItemsList = selectedOrder.Items.map(
//   //       (foodItem, index) =>
//   //         `<li key=${index}>${foodItem.Name} x${foodItem.Scoops} (₦${foodItem.Price})</li>`
//   //     ).join("");

//   //     const contentToPrint = `
//   //       <h1>Order ID: ${selectedOrder.TrxRef}</h1>
//   //       <p>Date: ${renderOrderDate(selectedOrder.CreatedAt)}</p>
//   //       <h2>Food Items:</h2>
//   //       <ul>
//   //         ${foodItemsList}
//   //       </ul>
//   //     `;

//   //     const printWindow = window.open("", "_blank");
//   //     printWindow.document.write(`
//   //       <html>
//   //         <head>
//   //           <title>Print</title>
//   //         </head>
//   //         <body>
//   //           ${contentToPrint}
//   //         </body>
//   //       </html>
//   //     `);
//   //     printWindow.document.close();
//   //     printWindow.print();
//   //   }
//   // };

//   // const isBasicStaff = userData && userData.Role === "basic";
//   // if (isBasicStaff) {
//   //   return (
//   //     <div style={{ marginTop: "2rem", marginLeft: "7rem" }}>
//   //       <Card title="Order History" style={{ width: "60rem" }}>
//   //         <p>You do not have permission to access this page.</p>
//   //       </Card>
//   //     </div>
//   //   );
//   // }
//   // console.log("filteredKitchenOrders: ", currentPage);
//   return (
//     <div className="p-4 md:p-6 lg:p-8">
//       {/* Search Input */}
//       <div className="flex justify-end mb-4">
//         <Input.Search
//           placeholder="Search Order ID"
//           value={searchText}
//           onChange={(e) => setSearchText(e.target.value)}
//           onSearch={handleSearch}
//           allowClear
//           className="w-full md:w-80 lg:w-96 border rounded-lg p-2 shadow-sm"
//         />
//       </div>

//       {/* Order History Card */}
//       <div className="bg-white border border-orange-500 rounded-xl shadow-lg p-4 md:p-6 lg:p-8 overflow-y-auto h-[70vh]">
//         <h2 className="text-xl font-semibold text-gray-800 mb-4">
//           Order History
//         </h2>
//         <Table
//           dataSource={filteredKitchenOrders}
//           columns={tableColumns}
//           rowKey={"TrxRef"}
//           onRow={(record) => ({
//             onClick: () => handleOrderClick(record),
//           })}
//           pagination={{
//             current: currentPage,
//             pageSize: ordersHistory?.pagination?.datasPerPage,
//             total:
//               parseInt(ordersHistory?.pagination?.datasPerPage) *
//               parseInt(ordersHistory?.pagination?.totalPages),
//             onChange: handlePageChange,
//           }}
//           className="w-full"
//         />
//       </div>

//       {/* Order Details Modal */}
//       <Modal
//         title={`Order Details - ${selectedOrder ? selectedOrder.TrxRef : ""}`}
//         open={isModalVisible}
//         onCancel={handleModalClose}
//         footer={null}
//         className="rounded-lg"
//       >
//         {selectedOrder && (
//           <div className="space-y-4">
//             <p className="text-lg font-medium">
//               <span className="font-semibold">Order ID:</span>{" "}
//               {selectedOrder.TrxRef}
//             </p>
//             <p className="text-gray-600">
//               <span className="font-semibold">Description:</span>{" "}
//               {selectedOrder.Description}
//             </p>
//             <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
//               <p className="font-semibold">Food Details:</p>
//               <ul className="list-disc pl-5">
//                 {selectedOrder.Items.map((foodItem, index) => (
//                   <li key={index} className="text-gray-700">
//                     {foodItem.Name} x{foodItem.Scoops} (₦{foodItem.Price})
//                   </li>
//                 ))}
//               </ul>
//             </div>
//             <p className="text-gray-700">
//               <span className="font-semibold">Status:</span>{" "}
//               {renderOrderStatus(selectedOrder.status, selectedOrder.IsPaid)}
//             </p>
//             <p className="text-green-600 font-semibold">
//               <span className="text-gray-800">Total Price:</span> ₦
//               {selectedOrder.TotalAmount}
//             </p>
//             <p className="text-gray-500">
//               <span className="font-semibold">Date:</span>{" "}
//               {renderOrderDate(selectedOrder.CreatedAt)}
//             </p>
//           </div>
//         )}
//       </Modal>
//     </div>
//   );
// }

// export default History;

import React, { useState, useEffect } from "react";
import { Input, Modal } from "antd";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../../Store/store";
import { GetKitchenOrdersHistory } from "../../../Features/kitchenSlice";

function History() {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userData, ordersHistory } = useAppSelector(selectKitchen);
  const [kitchenOrders, setKitchenOrders] = useState([]);
  const [filteredKitchenOrders, setFilteredKitchenOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(GetKitchenOrdersHistory(userData?.KitchenEmail, currentPage));
  }, [dispatch, userData, currentPage]);

  useEffect(() => {
    if (ordersHistory) {
      const paidOrders = ordersHistory?.data?.Orders.filter(
        (order) => order.IsPaid === true
      );
      const sortedOrders = paidOrders.sort(
        (a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt)
      );
      setKitchenOrders(sortedOrders);
      setFilteredKitchenOrders(sortedOrders);
    }
  }, [ordersHistory]);

  const handleSearch = () => {
    const lowerSearchText = searchText.toLowerCase();
    setFilteredKitchenOrders(
      kitchenOrders.filter((order) =>
        order.TrxRef.toLowerCase().includes(lowerSearchText)
      )
    );
  };

  // useEffect(() => {
  //   fetchKitchenOrders();
  // }, [userData, auth, ordersHistory]);

  const sortOrders = (ordersHistory) => {
    return ordersHistory.sort((a, b) => {
      const dateA = new Date(a.CreatedAt);
      const dateB = new Date(b.CreatedAt);
      return dateB - dateA;
    });
  };

  useEffect(() => {
    if (searchText) {
      handleSearch();
    } else {
      const sortedOrders = sortOrders(kitchenOrders);
      setFilteredKitchenOrders(sortedOrders);
    }
  }, [searchText, kitchenOrders]);

  // Paginated Data
  const paginatedOrders = filteredKitchenOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredKitchenOrders.length / itemsPerPage);

  const renderOrderStatus = (orderStatus, isPaid) => {
    const paymentColor = isPaid
      ? "bg-green-500 text-white"
      : "bg-red-500 text-white";
    return (
      <div className="flex gap-2">
        <span className={`px-2 py-1 rounded-lg ${paymentColor}`}>
          {isPaid ? "Paid" : "Not Paid"}
        </span>
      </div>
    );
  };

  const renderOrderDate = (dateString) => {
    const date = new Date(dateString);

    return (
      date.toLocaleDateString("en-GB", {
        weekday: "short", // "Wed"
        day: "numeric", // "9"
        month: "short", // "Feb"
        year: "numeric", // "2024"
      }) +
      ", " +
      date
        .toLocaleTimeString("en-GB", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true, // "9 PM"
        })
        .toLowerCase()
    );
  };

  return (
    <div className="p-4 md:p-6 lg:p-8">
      {/* Search Input */}
      <div className="flex justify-end mb-4">
        <Input.Search
          placeholder="Search Order ID"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={handleSearch}
          allowClear
          className="p-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
        />
      </div>

      {/* Order History Table */}
      <div className="p-2 md:p-6 lg:ml-40 overflow-y-auto h-[70vh]">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="text-black">
              <th className="p-2 border">Order ID</th>
              <th className="p-2 border">Food Details</th>
              <th className="p-2 border">Description</th>
              <th className="p-2 border">Status</th>
              <th className="p-2 border">Date</th>
              <th className="p-2 border">Total Price</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order) => (
              <tr
                key={order.TrxRef}
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setSelectedOrder(order);
                  setIsModalVisible(true);
                }}
              >
                <td className="p-2 border text-center">{order.TrxRef}</td>
                <td className="p-2 border">
                  {order.Items.map((item) => item.Name).join(", ")}
                </td>
                <td className="p-2 border">{order.Description}</td>
                <td className="p-2 border">
                  {renderOrderStatus(order.status, order.IsPaid)}
                </td>
                <td className="p-2 border">
                  {renderOrderDate(order.CreatedAt)}
                </td>
                <td className="p-2 border font-semibold text-green-600">
                  ₦{order.TotalAmount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-orange-500 text-white hover:bg-orange-600"
            }`}
          >
            Previous
          </button>

          <span className="text-gray-700">
            Page {currentPage} of {totalPages}
          </span>

          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-orange-500 text-white hover:bg-orange-600"
            }`}
          >
            Next
          </button>
        </div>
      </div>

      {/* Order Details Modal */}
      <Modal
        title={`Order Details of ID ${
          selectedOrder ? selectedOrder.TrxRef : ""
        }`}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="rounded-lg"
      >
        {selectedOrder && (
          <div className="space-y-4">
            {/* <p className="text-lg font-medium">
              <span className="font-semibold">Order ID:</span>{" "}
              {selectedOrder.TrxRef}
            </p> */}
            <p className="text-gray-600">
              <span className="font-semibold">Description:</span>{" "}
              {selectedOrder.Description}
            </p>
            <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
              <p className="font-semibold">Food Details:</p>
              <ul className="list-disc pl-5">
                {selectedOrder.Items.map((foodItem, index) => (
                  <li key={index} className="text-gray-700">
                    {foodItem.Name} x{foodItem.Scoops} (₦{foodItem.Price})
                  </li>
                ))}
              </ul>
            </div>
            <p className="text-gray-700">
              <span className="font-semibold">Status:</span>{" "}
              {selectedOrder.IsPaid ? "PAYMENT SUCCESSFUL" : "PAYMENT FAILED"}
            </p>
            <p className="text-green-600 font-semibold">
              <span className="text-gray-800">Total Price:</span> ₦
              {selectedOrder.TotalAmount}
            </p>
            <p className="text-gray-500">
              <span className="font-semibold">Date:</span>{" "}
              {renderOrderDate(selectedOrder.CreatedAt)}
            </p>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default History;
